import { composeRenderProps, Button as RACButton, type ButtonProps as RACButtonProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';

import { focusRing } from './component-utils';
import { ProgressCircle } from './progress-circle';
import { getDomainValue } from 'utils/domain';

export type ButtonProps = RACButtonProps & {
  variant?: 'solid' | 'outline' | 'text' | 'none';
  color?: 'primary' | 'error';
  decoration?: 'underline' | 'none';
  children: React.ReactNode;
};

// Ponemos el ! important en los colores de hover porque antd cambia el color de los links en hover
export const btnStyles = tv({
  extend: focusRing,
  base: 'group relative flex items-center justify-center text-center transition disabled:cursor-not-allowed lab:justify-start',
  variants: {
    variant: {
      solid: 'border shadow-sm disabled:border-disabled disabled:bg-disabled disabled:text-copy lab:shadow-none lab:disabled:text-inverted',
      outline: 'disabled:border-neutral/10 border shadow-sm disabled:border-disabled disabled:text-disabled lab:shadow-none',
      text: 'bg-transparent underline-offset-2 disabled:text-disabled',
    },
    color: {
      primary: '',
      accent: '',
      error: '',
    },
  },
  compoundVariants: [
    // order by color
    {
      variant: 'solid',
      color: 'primary',
      className:
        'border-dark bg-neutral-inverted text-inverted hover:bg-neutral hover:text-copy pressed:bg-neutral lab:hover:border-bold lab:hover:bg-bold lab:hover:text-inverted lab:pressed:border-bold lab:pressed:bg-bold lab:pressed:text-inverted',
    },
    {
      variant: 'outline',
      color: 'primary',
      className:
        'border-dark bg-transparent text-copy hover:bg-neutral-inverted hover:!text-inverted pressed:bg-neutral-inverted lab:hover:border-faint lab:hover:bg-faint lab:hover:!text-copy lab:pressed:border-faint lab:pressed:bg-faint lab:pressed:!text-copy',
    },
    {
      variant: 'text',
      color: 'primary',
      className: 'text-copy hover:!text-copy lab:hover:underline lab:pressed:underline',
    },
    {
      variant: 'solid',
      color: 'error',
      className: 'border-error bg-error text-inverted hover:bg-neutral hover:text-error pressed:bg-neutral',
    },
    {
      variant: 'outline',
      color: 'error',
      className:
        'border-error bg-neutral text-error hover:!text-inverted pressed:bg-error lab:hover:border-transparent lab:hover:bg-error/30 lab:hover:!text-error lab:pressed:border-transparent lab:pressed:bg-error/30 lab:pressed:!text-error',
    },
    {
      variant: 'text',
      color: 'error',
      className: 'text-error hover:!text-error lab:hover:underline lab:pressed:underline',
    },
  ],
  defaultVariants: {
    variant: 'solid',
    color: 'primary',
  },
});

export const noneBtnStyles = tv({
  base: 'group',
  extend: focusRing,
});

const camperClassName = 'h-10 px-4 text-sm';
const labDefaultClassName = 'h-8 px-2 text-sm font-bold uppercase lg:h-6 lg:text-xs';

export const btnBaseClassName = getDomainValue({ camper: camperClassName, nnormal: camperClassName, camperlab: labDefaultClassName });
/**
 * lab default class: 'h-8 px-2 text-sm font-bold uppercase lg:h-6 lg:text-xs'
 * @example
 * ```tsx
 * <Button variant="solid" color="primary">Solid</Button>
 * <Button variant="outline" color="highlight">Outline</Button>
 * <Button variant="text" color="error">Text</Button>
 */
export function Button({ variant = 'solid', color, className, children, decoration = 'none', ...rest }: ButtonProps) {
  const defaultClassName = {
    solid: className ?? btnBaseClassName,
    outline: className ?? btnBaseClassName,
    destructive: className ?? btnBaseClassName,
    text: className ?? btnBaseClassName,
    none: className,
  }[variant];

  return (
    <RACButton
      {...rest}
      className={
        variant === 'none' ?
          composeRenderProps(defaultClassName, (className, renderProps) => noneBtnStyles({ ...renderProps, className }))
        : composeRenderProps(defaultClassName, (className, renderProps) => btnStyles({ ...renderProps, variant, color, className }))
      }
    >
      {({ isPending }) => (
        <>
          <span className="inline-flex items-center gap-1" style={isPending ? { opacity: 0 } : undefined}>
            {children}
          </span>
          {isPending ?
            <div className="absolute inset-0 flex items-center justify-center lab:justify-start lab:px-2">
              <ProgressCircle aria-label="pending" />
            </div>
          : null}
        </>
      )}
    </RACButton>
  );
}
